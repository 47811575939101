<script>
import axios from "axios";
import Layout from "../../layouts/main";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

import { required, email, helpers, minLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import _ from "lodash";
/**
 * Forgot Password component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    form: {
      firstName: {
        required: helpers.withMessage("First Name is required", required),
      },
      lastName: {
        required: helpers.withMessage("Last Name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      phone: {
        required: helpers.withMessage("First Name is required", required),
      },
    },
    password: {
      currentPassword: {
        required: helpers.withMessage("Password is required", required),
      },
      newPassword: {
        required: helpers.withMessage("New Password is required", required),
        minLength: minLength(8),
      },
      password_confirmation: {
        required: helpers.withMessage("Confirm Password is required", required),
      },
    }
  },
  page: {
    title: "Settings",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      submitted: false,
      error: null,
      errors: [],
      success: null,
      profileErrors: [],
      alertError: false,
      alertErrors: false,
      alertSuccess: false,
      tryingToReset: false,
      isResetError: false,
      profileAlert: false,
      app_URL: process.env.VUE_APP_URL,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
      password: {
        currentPassword: "",
        newPassword: "",
        password_confirmation: "",
      }
    };
  },
  created() {
    this.getProfile();
  },
  activated() {
    this.getProfile();
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    updateProfile() {
      this.submitted = true;
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        return false;
      } else {
        axios.post(this.app_URL + "api/updateProfile", this.form).then(() => {
          this.$store.state.isAccepted = true;
          this.$store.state.notificationData = "Profile updated successfully";
          setTimeout(() => {
            this.$store.state.isAccepted = false;
            this.$store.state.notificationData = "";
          }, 2500);
          this.getProfile();
        }).catch(error => {
          console.log(error);
          this.profileErrors = error.response.data.errors;
          this.profileAlert = true;
        });
      }
    },
    getProfile() {
      axios.post(this.app_URL + "api/getProfile", { id: atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId')))) }).then((res) => {
        this.form = res.data;
      });
    },
    changePassword() {
      this.submitted = true;
      this.v$.password.$touch();
      if (this.v$.password.$invalid) {
        return false;
      } else {
        let form = new FormData();
        form.append('id', this.$store.state.authenticatedUserId);
        _.each(this.password, (value, key) => {
          form.append(key, value);
        });
        axios.post(this.app_URL + "api/changePassword", form).then((res) => {
          if (res.data.error) {
            this.error = res.data.message;
            this.alertError = true;

          } else {
            this.success = res.data.message;
            this.alertSuccess = true;
            this.alertError = false;
            this.alertErrors = false;
            this.$store.state.isAccepted = true;
            this.$store.state.notificationData = "Password updated successfully";
            setTimeout(() => {
              this.$store.state.isAccepted = false;
              this.$store.state.notificationData = "";
            }, 2500);
          }
        }).catch(error => {
          console.log(error)
          this.errors = error.response.data.errors.password_confirmation;
          this.alertErrors = true;
        })
      }
    }
  },
};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">Account</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="card overflow-hidden">
            <!-- <div class="bg-soft">
            <div class="row"><div class="col-12 text-center py-3">
              <img src="/img/whinchester.4ab63a21.png" alt="whinchester Logo" height="80"></div></div>
          </div> -->
            <div class="card-body pt-0">
              <div>
                <router-link to="/">
                </router-link>
              </div>

              <div class="p-2">
                <form>
                  <div class="mb-3">
                    <h2 style="font-size:20px;margin-bottom:10px;margin-top:10px;"> Change Your Password</h2>
                    <p style="font-size:14px;margin-bottom:15px;">Please fill in the fields below to change your password
                    </p>
                    <b-alert v-model="alertError" class="mb-4" variant="danger" dismissible>{{ error }}</b-alert>
                    <b-alert v-model="alertErrors" class="mb-4" variant="danger" dismissible>{{ errors[0] }}</b-alert>
                    <b-alert v-model="alertSuccess" class="mb-4" variant="success" dismissible>{{ success }}</b-alert>
                    <label for="useremail">Old Password</label>
                    <input type="password" v-model="password.currentPassword" class="form-control" id="password"
                      placeholder="Your Old password here"
                      :class="{ 'is-invalid': submitted && v$.password.currentPassword.$error }" />
                    <div v-for="(item, index) in v$.password.currentPassword.$errors" :key="index"
                      class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                    <div class="pt-3">
                      <label for="useremail">New Password</label>
                      <input type="password" v-model="password.newPassword" class="form-control" id="new-password"
                        placeholder="Your New password here"
                        :class="{ 'is-invalid': submitted && v$.password.newPassword.$error }" />
                      <div v-for="(item, index) in v$.password.newPassword.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                    <div class="pt-3">
                      <label for="useremail">Confirm Password</label>
                      <input type="password" v-model="password.password_confirmation" class="form-control"
                        id="cnfrm-password" placeholder="Confirm your password here"
                        :class="{ 'is-invalid': submitted && v$.password.password_confirmation.$error }" />
                      <div v-for="(item, index) in v$.password.password_confirmation.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 row mb-0">
                    <div class="col-12 text-end">
                      <button class="btn btn-theme w-md" type="button" @click.prevent="changePassword">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->

          <div class="mt-5 text-center">
            <!-- <p>
            Remember It ?
            <router-link to="/login" class="fw-medium text-primary"
              >Sign In here</router-link
            >
          </p> -->
            <!-- <p>
            © {{ new Date().getFullYear() }}Whinchester. Crafted with 
            <i class="mdi mdi-heart text-danger"></i> by Midriff
          </p> -->
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6">
          <div class="card overflow-hidden">
            <!-- <div class="bg-soft">
            <div class="row"><div class="col-12 text-center py-3">
              <img src="/img/whinchester.4ab63a21.png" alt="whinchester Logo" height="80"></div></div>
          </div> -->
            <div class="card-body pt-0">
              <div>
                <router-link to="/">
                </router-link>
              </div>

              <div class="p-2">
                <form>
                  <div class="mb-3">
                    <h2 style="font-size:20px;margin-bottom:10px;margin-top:10px;"> Personal Details</h2>
                    <p style="font-size:14px;margin-bottom:15px;">To change yopur personal detail, edit and save from here
                    </p>
                    <b-alert v-model="profileAlert" class="mb-4" variant="danger" dismissible>{{ profileErrors
                    }}</b-alert>
                    <label for="lastName">First Name</label>
                    <input type="text" v-model="form.firstName" class="form-control" placeholder="First Name"
                      :class="{ 'is-invalid': submitted && v$.form.firstName.$error }" />
                    <div v-for="(item, index) in v$.form.firstName.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                    <label for="firstName">Last Name</label>
                    <input type="text" v-model="form.lastName" class="form-control" placeholder="Last Name"
                      :class="{ 'is-invalid': submitted && v$.form.lastName.$error }" />
                    <div v-for="(item, index) in v$.form.lastName.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                    <div class="pt-3">
                      <label for="Email">Email</label>
                      <input type="email" v-model="form.email" class="form-control" placeholder="email"
                        :class="{ 'is-invalid': submitted && v$.form.email.$error }" />
                      <div v-for="(item, index) in v$.form.email.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                    <div class="pt-3">
                      <label for="phone">Phone</label>
                      <input type="number" v-model="form.phone" class="form-control" placeholder="Phone No"
                        :class="{ 'is-invalid': submitted && v$.form.phone.$error }" />
                      <div v-for="(item, index) in v$.form.phone.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>
                    <!-- <div class="d-block mb-3">
                      <label class="font-size-14">Address</label><textarea type="textarea" class="form-control" rows="3"
                        placeholder="address"></textarea>
                    </div> -->
                  </div>
                  <div class="mb-3 row mb-0">
                    <div class="col-12 text-end">
                      <button class="btn btn-theme w-md" type="button" @click.prevent="updateProfile">
                        Save
                      </button>
                      <!-- <button class="btn btn-outline-dark ms-2 w-md" type="submit">
                        Cancel
                      </button> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->

        </div>
      </div>
    </main>
  </Layout>
</template>

<style lang="scss" module></style>
